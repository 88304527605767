import { RawCellContent } from "hyperformula";
import {
  FinancialItemComputed,
  FinancialItemType,
  FinancialPeriodItem,
  FinancialStatementItems,
  CashFlowCandidates,
} from "@interfold-ai/shared/models/FinancialStatement";
import { GridRow } from "src/classes/GridState";
import { parseTaxCellAsFloatOrUndefined } from "@interfold-ai/shared/utils/parseTaxCellAsFloat";
import { colNumberToExcelCol } from "src/classes/RenderedDoc";
import { sanitizeTabName } from "./utils";
import { AutoRenderedSheetBuilder } from "./AutoRenderedSheetBuilder";
import { FinancialStatementCashFlowItems } from "@interfold-ai/shared/models/FinancialStatement";

export enum RawFinancialStatementType {
  IncomeStatement = "incomeStatement",
  BalanceSheet = "balanceSheet",
  CashFlowStatement = "cashFlowStatement",
}

const similarItem = (target: string, searchItem: string) => {
  return target.toLowerCase().includes(searchItem.toLowerCase());
};

const getCellReference = (colIndex: number, rowIndex: number): string => {
  const colLetter = colNumberToExcelCol(colIndex + 2);
  return `${colLetter}${rowIndex + 1}`;
};

export const combineFinancialStatementItems = (
  financialStatementItems: FinancialStatementItems[],
): FinancialStatementItems | null => {
  if (!financialStatementItems.length) {
    return null;
  }

  if (financialStatementItems.length === 1) {
    return financialStatementItems[0] || null;
  }

  const combined: FinancialStatementItems = {
    ...financialStatementItems[0],
    incomeStatement: {
      availablePeriods: [],
      items: [],
    },
    balanceSheet: {
      availablePeriods: [],
      items: [],
    },
    cashFlowStatement: {
      availablePeriods: [],
      items: [],
    },
    calculatedCashFlowItems: null,
  };

  const incomeStatementPeriods = new Set<string>();
  const balanceSheetPeriods = new Set<string>();
  const cashFlowPeriods = new Set<string>();

  financialStatementItems.forEach((statement) => {
    statement.incomeStatement?.availablePeriods?.forEach((period) =>
      incomeStatementPeriods.add(period),
    );
    statement.balanceSheet?.availablePeriods?.forEach((period) => balanceSheetPeriods.add(period));
    statement.cashFlowStatement?.availablePeriods?.forEach((period) => cashFlowPeriods.add(period));
  });

  combined.incomeStatement.availablePeriods = Array.from(incomeStatementPeriods);
  combined.balanceSheet.availablePeriods = Array.from(balanceSheetPeriods);
  combined.cashFlowStatement.availablePeriods = Array.from(cashFlowPeriods);

  const mergePeriodValues = (
    existingValues: Record<string, string | null> | null = null,
    newValues: Record<string, string | null> | null = null,
  ) => {
    if (!existingValues && !newValues) {
      return null;
    }
    if (!existingValues) {
      return newValues;
    }
    if (!newValues) {
      return existingValues;
    }
    return { ...newValues, ...existingValues };
  };

  const mergeItems = (items: FinancialPeriodItem[]): FinancialPeriodItem[] => {
    const mergedItems = new Map<string, FinancialPeriodItem>();

    // newer period document items take precedence
    [...items].reverse().forEach((item) => {
      const existing = mergedItems.get(item.name);
      if (!existing) {
        mergedItems.set(item.name, { ...item });
      } else {
        existing.periodValues = mergePeriodValues(item.periodValues, existing.periodValues);

        if (item.nestedItems?.length) {
          existing.nestedItems = mergeItems([...item.nestedItems, ...(existing.nestedItems || [])]);
        }

        if (item.total) {
          existing.total = existing.total
            ? {
              ...item.total,
              periodValues: mergePeriodValues(
                item.total.periodValues,
                existing.total.periodValues,
              ),
            }
            : item.total;
        }
      }
    });

    return Array.from(mergedItems.values());
  };

  combined.incomeStatement.items = mergeItems(
    financialStatementItems.flatMap((statement) => statement.incomeStatement?.items || []),
  );
  combined.balanceSheet.items = mergeItems(
    financialStatementItems.flatMap((statement) => statement.balanceSheet?.items || []),
  );
  combined.cashFlowStatement.items = mergeItems(
    financialStatementItems.flatMap((statement) => statement.cashFlowStatement?.items || []),
  );
  combined.calculatedCashFlowItems = financialStatementItems.reduce((acc, statement) => {
    if (statement.calculatedCashFlowItems) {
      const uniqueItems = new Set([
        ...(acc.items || []),
        ...statement.calculatedCashFlowItems.items,
      ]);
      acc.items = Array.from(uniqueItems);
      acc.netIncomeItem = statement.calculatedCashFlowItems.netIncomeItem;
    }
    return acc;
  }, {} as FinancialStatementCashFlowItems);
  return combined;
};

/**
 * This function is used to adjust the interest values for the cash flow tab.
 * It is used to ensure that the interest values are properly signed for the cash flow tab.
 *
 * It only matters if expenses are recorded as negative numbers in the Financials tab.
 * Since we reference that tab for calculating the Cash Flow we need to adjust the sign
 * in some cases.
 *
 * @param candidates - The cash flow candidates.
 * @param rows - The rows of the cash flow tab.
 */
export const adjustInterestValuesForCashFlow = (
  candidates: CashFlowCandidates,
  rows: GridRow[],
) => {
  const interestRows = candidates.interest;
  const expenseTerms = ["expense", "expenses"];
  interestRows.forEach((interestRow) => {
    let processed = false;
    const description = interestRow.rowKey;
    let term = description.match(/\((.*)\)/)?.[1];

    // no term, we need to work backwards to find the root item for this interest row
    if (!term || term.trim() === "") {
      // work backwards to find the root item for this interest row
      for (let i = interestRow.rowIndex - 1; i >= 0; i--) {
        const row = rows[i];
        term = (row?.rowDataArray[0] as string)?.match(/\((.*)\)/)?.[1];
        // if we have a term or we reach the root element
        if (term || row?.rowMetadata?.levelIndex === 0) {
          break;
        }
      }
    }
    if (term && term.trim() !== "" && expenseTerms.includes(term.toLowerCase().trim())) {
      processed = true;
      const gridRow = rows.find((row) => row.rowDataArray[0] === description);
      if (gridRow) {
        gridRow.rowDataArray.slice(1).forEach((cell) => {
          if (cell) {
            // always reverse the sign
            // we want to add expenses (we want them to be positive number)
            // we want to subtract revenue/income (we want them to be negative numbers)
            interestRow.multipliers = interestRow.multipliers || [];
            interestRow.multipliers.push(-1);
          }
        });
      }
    }

    if (!processed) {
      const netIncomeRowCandidate = candidates.netIncome[0];
      const rowKey = netIncomeRowCandidate.rowKey.toLowerCase();
      const interestRowKey = interestRow.rowKey.toLowerCase();
      if (rowKey.includes("net income") || rowKey.includes("net loss")) {
        if (
          interestRowKey.includes("interest revenue") ||
          interestRowKey.includes("interest income")
        ) {
          const gridRow = rows.find((row) => row.rowDataArray[0] === description);
          if (gridRow) {
            gridRow.rowDataArray.slice(1).forEach((cell) => {
              if (cell) {
                // negate the interest row
                interestRow.multipliers = interestRow.multipliers || [];
                interestRow.multipliers.push(-1);
              }
            });
          }
        }
      }
    }
  });
};

export const cashFlowCandidateRowsFromItems = (
  rows: GridRow[],
  items: FinancialStatementCashFlowItems,
): CashFlowCandidates => {
  const usedRowIndices = new Set<number>();
  const getLastMatchingRow = (itemName: string) => {
    const matchingRows = rows.filter(
      (row) => row.rowDataArray[0] === itemName && !usedRowIndices.has(row.index)
    );
    //TODO: If we need to add more complex logic comparing and making sure certain rows are included, this is
    // where we would do it.
    const lastMatchingRow = matchingRows[matchingRows.length - 1];
    if (lastMatchingRow) {
      usedRowIndices.add(lastMatchingRow.index);
      return {
        rowIndex: lastMatchingRow.index,
        rowKey: lastMatchingRow.rowDataArray[0] as string,
      };
    }
    return null;
  };

  const netIncomeRows = [];
  const netIncomeMatch = getLastMatchingRow(items.netIncomeItem);
  if (netIncomeMatch) {
    netIncomeRows.push(netIncomeMatch);
  }

  const addBackRows = items.items
    .map(getLastMatchingRow)
    .filter((row): row is NonNullable<typeof row> => row !== null);

  // looks like depreciation
  const depreciation = addBackRows.filter((row) =>
    similarItem(row.rowKey, FinancialItemType.Depreciation),
  );

  // looks like amortization
  const amortization = addBackRows.filter(
    (row) => similarItem(row.rowKey, FinancialItemType.Amortization) && !depreciation.includes(row),
  );

  // everything else
  const interest = addBackRows.filter(
    (row) => !depreciation.includes(row) && !amortization.includes(row),
  );

  return {
    netIncome: netIncomeRows,
    depreciation,
    interest,
    amortization,
  };
};

export const cashFlowCandidateRows = (rows: GridRow[]): CashFlowCandidates => {
  const usedRowIndices = new Set<number>();

  const netIncomeRows = rows
    .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.NetIncome))
    .filter((row) => row.rowDataArray.slice(1).some((cell) => cell))
    .filter((row) => !usedRowIndices.has(row.index))
    .map((row) => {
      usedRowIndices.add(row.index);
      return {
        rowIndex: row.index,
        rowKey: row.rowDataArray[0] as string,
      };
    })
    .slice(0, 1);

  const depreciationRows = rows
    .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.Depreciation))
    .filter((row) => !usedRowIndices.has(row.index))
    .map((row) => {
      usedRowIndices.add(row.index);
      return {
        rowIndex: row.index,
        rowKey: row.rowDataArray[0] as string,
      };
    });

  const interestRows = rows
    .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.Interest))
    .filter((row) => !usedRowIndices.has(row.index))
    .map((row) => {
      usedRowIndices.add(row.index);
      return {
        rowIndex: row.index,
        rowKey: row.rowDataArray[0] as string,
        negateValue: false,
      };
    });

  const amortizationRows = rows
    .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.Amortization))
    .filter((row) => !usedRowIndices.has(row.index))
    .map((row) => {
      usedRowIndices.add(row.index);
      return {
        rowIndex: row.index,
        rowKey: row.rowDataArray[0] as string,
      };
    });

  return {
    netIncome: netIncomeRows,
    depreciation: depreciationRows,
    interest: interestRows,
    amortization: amortizationRows,
  };
};

export const buildCashFlowFromFinancialsTab = (
  candidateRows: CashFlowCandidates,
  allPeriods: string[],
  sourceTabName: string,
) => {
  const sanitizedTabName = sanitizeTabName(sourceTabName);
  const rendered = new AutoRenderedSheetBuilder({}, {}, 0, "B", {
    showDeleteRowOption: true,
    supressContextMenu: false,
  });

  const periodCellRefs: Record<number, string[]> = {};
  allPeriods.forEach((_, i) => (periodCellRefs[i] = []));

  rendered.addRow(() => ["", ...allPeriods], "text", "highlighted");

  Object.entries(candidateRows).forEach(([_key, rows]) => {
    if (rows.length === 0) {
      return;
    }
    rows.forEach((row) => {
      const newRowIndex = rendered.body.length;
      rendered.addRow(() => {
        const rowData = [
          row.rowKey,
          ...allPeriods.map((_, i) => {
            const multiplier = row.multipliers?.[i];
            let multiplierFormula = "";
            if (multiplier) {
              multiplierFormula = ` * ${multiplier}`;
            } else {
              multiplierFormula = "";
            }
            const cellRef = `='${sanitizedTabName}'!${getCellReference(i, row.rowIndex)}${multiplierFormula}`;
            // can we remove this?
            periodCellRefs[i].push(`${getCellReference(i, newRowIndex)}`);
            return cellRef;
          }),
        ];
        return rowData;
      });
    });
  });

  rendered.addRow(
    () => [
      FinancialItemComputed.BusinessCashFlowBeforeTax,
      ...allPeriods.map((_, i) => {
        if (!periodCellRefs[i].length) {
          return "";
        }
        const firstCell = periodCellRefs[i][0];
        const lastCell = periodCellRefs[i][periodCellRefs[i].length - 1];
        return `=SUM(${firstCell}:${lastCell})`;
      }),
    ],
    "number",
    "highlighted",
  );

  return rendered;
};

export const shouldDisplayItemValue = (item: FinancialPeriodItem, period: string) => {
  if (item.periodValues && item.total?.periodValues) {
    return parseTaxCellAsFloatOrUndefined(item.periodValues[period] || "") !== parseTaxCellAsFloatOrUndefined(item.total.periodValues[period] || "");
  }
  return true;
};

export const convertToGridRows = (
  jsonData: FinancialStatementItems,
  type: RawFinancialStatementType,
): GridRow[] => {
  let rowIndex = 0;

  const processItem = (
    item: FinancialPeriodItem,
    allPeriods: string[],
    levelIndex: number,
  ): GridRow[] => {
    const gridRows: GridRow[] = [];

    const rowDataArray: RawCellContent[] = [
      item.name,
      ...allPeriods.map((period) => {
        if (shouldDisplayItemValue(item, period)) {
          return parseTaxCellAsFloatOrUndefined(item.periodValues?.[period] || "");
        }
        return "";
      }),
    ];
    const gridRow: GridRow = {
      rowDataArray,
      rowDataType: "number",
      rowStyle: levelIndex === 0 ? "highlighted" : "standard",
      rowMetadata: {
        levelIndex,
      },
      isManagedByApp: true,
      isEditable: false,
      index: rowIndex++,
    };
    gridRows.push(gridRow);

    item.nestedItems?.forEach((nestedItem) => {
      gridRows.push(...processItem(nestedItem, allPeriods, levelIndex + 1));
    });

    if (item.total) {
      const totalRowDataArray: RawCellContent[] = [
        item.total.name,
        ...allPeriods.map((period) =>
          parseTaxCellAsFloatOrUndefined(item.total?.periodValues?.[period] || ""),
        ),
      ];

      const totalGridRow: GridRow = {
        rowDataArray: totalRowDataArray,
        rowDataType: "number",
        rowStyle: "highlighted",
        rowMetadata: {
          levelIndex: levelIndex + 1,
        },
        isManagedByApp: true,
        isEditable: false,
        index: rowIndex++,
      };
      gridRows.push(totalGridRow);
    }

    return gridRows;
  };

  const gridRows: GridRow[] = [];
  let allPeriods: string[] = [];

  if (type === RawFinancialStatementType.IncomeStatement) {
    allPeriods = jsonData.incomeStatement.availablePeriods;
    gridRows.push({
      rowDataArray: [`${jsonData.periodContext || ""}`, ...allPeriods],
      rowDataType: "text",
      rowStyle: "highlighted",
      rowMetadata: {
        levelIndex: 0,
      },
      isManagedByApp: true,
      isEditable: false,
      index: rowIndex++,
    });
    jsonData.incomeStatement.items.forEach((item) => {
      gridRows.push(...processItem(item, allPeriods, 0));
    });
  } else if (type === RawFinancialStatementType.BalanceSheet) {
    allPeriods = jsonData.balanceSheet.availablePeriods;
    gridRows.push({
      rowDataArray: [`${jsonData.periodContext || ""}`, ...allPeriods],
      rowDataType: "text",
      rowStyle: "highlighted",
      rowMetadata: {
        levelIndex: 0,
      },
      isManagedByApp: true,
      isEditable: false,
      index: rowIndex++,
    });
    jsonData.balanceSheet.items.forEach((item) => {
      gridRows.push(...processItem(item, allPeriods, 0));
    });
  } else if (type === RawFinancialStatementType.CashFlowStatement) {
    allPeriods = jsonData.cashFlowStatement.availablePeriods;
    gridRows.push({
      rowDataArray: [`${jsonData.periodContext || ""}`, ...allPeriods],
      rowDataType: "text",
      rowStyle: "highlighted",
      rowMetadata: {
        levelIndex: 0,
      },
      isManagedByApp: true,
      isEditable: false,
      index: rowIndex++,
    });
    jsonData.cashFlowStatement.items.forEach((item) => {
      gridRows.push(...processItem(item, allPeriods, 0));
    });
  }

  return gridRows;
};
