import { SpreadAction } from "src/redux/actions/actions.constants";
import { createAction } from "@reduxjs/toolkit";
import {
  AnalysisType,
  ManualWorkFlowEnum,
} from "@interfold-ai/shared/models/SpreadsTabConstants";
import {
  PropertyGroups,
  NOIAnalysisProps,
  SheetIndex,
  TabName,
  JointlyOwnedAssets,
} from "src/redux/reducers/types";
import { HoverInfo, RenderedDoc, RenderedDocOptions } from "src/classes/RenderedDoc";
import { CellValue, RawCellContent } from "hyperformula";
import { GridState } from "src/classes/GridState";
import { ColDef } from "ag-grid-community";
import { SPREAD_USER_STATE } from "../reducers/spread.reducer";
import { ExtractOutput } from "@interfold-ai/shared/models/extract/common";;
import { TabGroup } from "src/classes/RenderedDocuments/helpers";
import { RawConfidenceContent } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { IncomeAssetParts } from "@interfold-ai/shared/models/Property";

export const CalculateSpread = createAction<
  NOIAnalysisProps | Record<string | number, ExtractOutput>
>(SpreadAction.CALCULATE_SPREAD);
export const SetWorkflow = createAction<AnalysisType>(SpreadAction.SET_WORKFLOW);
export const HideSheet = createAction<TabName>(SpreadAction.HIDE_SHEET);
export const ShowSheet = createAction<TabName>(SpreadAction.SHOW_SHEET);

export type ShouldUpdateDB = boolean;
export const GenerateSheets = createAction<ShouldUpdateDB>(SpreadAction.GENERATE_SHEETS);

export const SetSheets = createAction<{
  rawSheets: Record<TabName, RawCellContent[][]>;
  calculatedSheets: Record<string, CellValue[][]>;
}>(SpreadAction.SET_SHEETS);

export interface UpdateCellPayload {
  rowIndex: number;
  colName: string;
  docName: TabName;
}

export const UpdateCell = createAction<UpdateCellPayload>(SpreadAction.UPDATE_CELL);

export const AddRow = createAction<{
  rowIndex: number;
  where: "above" | "below";
  sheetIndex: SheetIndex;
  docName: TabName;
}>(SpreadAction.ADD_ROW);
export const RemoveRow = createAction<{ rowIndex: number, docName: TabName }>(SpreadAction.REMOVE_ROW);

export const SaveToDb = createAction(SpreadAction.SAVE_TO_DB);
export const FetchFromDb = createAction<{
  loanId: number | null;
  annualReviewId: number | null;
}>(SpreadAction.FETCH_FROM_DB);
export const SetFormulaBar = createAction<string>(SpreadAction.SET_FORMULA_BAR);
export const BeginEditingCell = createAction<{
  sheetIndex: SheetIndex;
  rowIndex: number;
  colIndex: number;
}>(SpreadAction.BEGIN_EDITING_CELL);
export const StopEditing = createAction(SpreadAction.STOP_EDITING);
export const SpreadError = createAction<Error>(SpreadAction.SPREAD_ERROR);
export const SetGridStates = createAction<Record<TabName, GridState>>(SpreadAction.SET_GRID_STATES);
export const SetGridOptions = createAction<Record<TabName, RenderedDocOptions>>(
  SpreadAction.SET_GRID_OPTIONS,
);
export const SetTabGroups = createAction<TabGroup[]>(SpreadAction.SET_TAB_GROUPS);
export const SetHoverInfos = createAction<Record<TabName, HoverInfo[][]>>(
  SpreadAction.SET_HOVER_INFOS,
);
export const SetRenderedDocs = createAction<Record<string, RenderedDoc>>(
  SpreadAction.SET_RENDERED_DOCS,
);

export const LoadFromId = createAction<number>(SpreadAction.LOAD_FROM_SLUG);

export const SetColumnDefs = createAction<Record<string, ColDef>>(SpreadAction.SET_COLUMN_DEFS);

export const SetCurrentSpreadId = createAction<number>(SpreadAction.SET_CURRENT_SPREAD_ID);

export const SetSubjectAssets = createAction<IncomeAssetParts[]>(SpreadAction.SET_SUBJECT_ASSETS);

export const SetSpreadUserState = createAction<SPREAD_USER_STATE>(SpreadAction.SET_USER_STATE);

export const ResetChooseAssets = createAction(SpreadAction.RESET_CHOOSE_ASSETS);

export const LoadManualWorkflowAction = createAction<ManualWorkFlowEnum>(
  SpreadAction.LOAD_MANUAL_WORKFLOW,
);

export const ResetSpreads = createAction(SpreadAction.RESET);

export const SetConfidenceData = createAction<Record<TabName, RawConfidenceContent[][]>>(
  SpreadAction.SET_CONFIDENCES,
);

export const SetPropertyGroups = createAction<PropertyGroups>(SpreadAction.SET_PROPERTY_GROUPS);

export const SetJointlyOwnedAssets = createAction<JointlyOwnedAssets[]>(
  SpreadAction.SET_JOINTLY_OWNED_ASSETS,
);

export const SetColSpans = createAction<Record<TabName, Record<string, number>>>(
  SpreadAction.SET_COL_SPANS,
);

export const SetSelectedAssets = createAction<string[]>(SpreadAction.SET_SELECTED_ASSETS);

const SpreadActions = {
  CalculateSpread,
  SetWorkflow,
  HideSheet,
  ShowSheet,
  AddRow,
  RemoveRow,
  UpdateCell,
  SaveToDb,
  SpreadError,
  FetchFromDb,
  SetFormulaBar,
  SetRenderedDocs,
  LoadManualWorkflowAction,
  ResetChooseAssets,
  ResetSpreads,
  SetHoverInfos,
  SetConfidenceData,
  SetPropertyGroups,
  SetSelectedAssets,
};

export default SpreadActions;
