import { Form1040 } from "@interfold-ai/shared/models/tax/Form1040";
import { SupportedLenderId, supportedLenderIds } from "@interfold-ai/shared/models/SpreadsConfig";
import { spreadConfig } from "@interfold-ai/shared/spreads-config";
import { ficaCalculationConfiguration } from "../FicaCalculationConfiguration";
import { LoanCalculatorData, LoanCalculatorRendered } from "../LoanCalculatorRendered";
import { RenderableWithConfidence } from "src/classes/RenderedDocuments/Workflows/TaxFormWithConfidence";
import { AutoRenderedSheetBuilderWithConfidence } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { StartAt } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilder";

export const Form1040RowHeadings = {
  WagesAndSalaries: "Wages and Salaries",
  InterestAndDividends: "Interest and Dividends",
  LessInterestAndDividendIncomeFromK1s: "Less: Interest & dividend Income from K-1's *",
  PensionsAndAnnuities: "Pensions and Annuities",
  SocialSecurityBenefits: "Social Security Benefits",
  ScheduleCEBIDA: "Schedule C EBIDA",
  ScheduleDCapitalGains: "Schedule D Capital Gains",
  LessCapitalGainsOrAddBackCapitalLossesFromK1s:
    "Less: Capital Gains or Add Back Capital Losses from K-1's *",
  ScheduleENetIncome: "Schedule E Net Income",
  PlusInterestExpense: "Plus: Interest Expense",
  PlusDepreciationAndAmortExpense: "Plus: Depreciation & Amort. Expense",
  ScheduleFCashFlow: "Schedule F Cash Flow *",
  K1DistributionsNetContributions: "K-1 Distributions, Net Contributions",
  Subtotal: "Subtotal",
  LessFederalTaxes: "Less: Federal Taxes",
  LessScheduleAStateAndLocalTaxes: "Less: Schedule A State & Local Taxes",
  LessFICAOtherEmployerDeductions: "Less: FICA/Other Employer Deductions",
  LessLivingExpenses: "Less: Living Expenses",
  CashFlowAvailableForDebtService: "Cash Flow Available for Debt Service",
  TotalDebtService: "Total Debt Service",
  PersonalDSCR: "Personal DSCR",
  ExcessCashFlow: "Excess Cash Flow",
};

export function createForm1040Rendered(
  data: RenderableWithConfidence<Form1040>,
  startingColumn: string = "B",
  startingRow: number = 1,
  k1Distribution: number = 0,
  scheduleETabName: string | null = null,
  scheduleCTabName: string | null = null,
  enableLoanCalculator: boolean = false,
) {
  const { renderable: form, confidence } = data;
  const shouldHideManualRows = supportedLenderIds.find((l) => l === form.lenderId)
    ? spreadConfig.lenderSettings[form.lenderId as SupportedLenderId]?.tempHideManualRows
    : false;

  const builder = new AutoRenderedSheetBuilderWithConfidence(
    form,
    confidence,
    Form1040RowHeadings,
    startingRow,
    startingColumn,
  );

  builder
    .addRowFromData("WagesAndSalaries", (proxyData) => proxyData.income?.wages, {
      fallbackValue: 0,
    })
    .addRowWithStaticValue("InterestAndDividends", calculateInterestAndDividends(form));

  if (!shouldHideManualRows) {
    builder.addRowWithStaticValue("LessInterestAndDividendIncomeFromK1s", 0);
  }

  builder
    .addRowWithStaticValue("PensionsAndAnnuities", calculatePensionsAndAnnuities(form))
    .addRowFromData(
      "SocialSecurityBenefits",
      (proxyData) => proxyData.income?.socialSecurityBenefits,
      { fallbackValue: 0 },
    )
    .addRowWithStaticValue("ScheduleCEBIDA", scheduleCEBIDACalculation())
    .addRowFromData(
      "ScheduleDCapitalGains",
      (proxyData) =>
        proxyData.schedules?.scheduleD?.capitalGainsAndLosses || proxyData.income?.capitalGains,
      { fallbackValue: 0 },
    );

  if (!shouldHideManualRows) {
    builder.addRowWithStaticValue("LessCapitalGainsOrAddBackCapitalLossesFromK1s", 0);
  }

  builder
    .addRowWithStaticValue("ScheduleENetIncome", scheduleENetIncomeCalculation())
    .addRowWithStaticValue("PlusInterestExpense", scheduleEInterestExpenseCalculation())
    .addRowWithStaticValue(
      "PlusDepreciationAndAmortExpense",
      scheduleEDepreciationAndAmortizationCalculation(),
    );

  if (!shouldHideManualRows) {
    builder.addRowWithStaticValue("ScheduleFCashFlow", 0);
  }

  builder
    .addRowWithAutoConfidence(
      ({ labels, resolve }) =>
        resolve(() => ({
          label: asterisk(labels.K1DistributionsNetContributions),
          value: k1Distribution,
        })),
      "number",
      "normal",
    )
    .addRowWithFormula("Subtotal", (finders, labels) =>
      calculateSubtotal(finders.columnReference, labels as typeof Form1040RowHeadings),
    )
    .addRowFromData("LessFederalTaxes", (proxyData) => proxyData.tax?.totalTax, {
      fallbackValue: 0,
    })
    .addRowFromData(
      "LessScheduleAStateAndLocalTaxes",
      (proxyData) => proxyData.schedules?.scheduleA?.stateAndLocalTaxes,
      { fallbackValue: 0 },
    )
    .addRowWithFormula("LessFICAOtherEmployerDeductions", (finders, labels) =>
      calculateFICADeduction(finders.columnReference(labels.WagesAndSalaries)),
    )
    .addRowWithStaticValue(asterisk(Form1040RowHeadings.LessLivingExpenses), 0)
    .addRowWithFormula(
      "CashFlowAvailableForDebtService",
      (finders, labels) =>
        `=${finders.columnReference(labels.Subtotal)} - SUM(${finders.columnReference(
          labels.LessFederalTaxes,
        )}:${finders.columnReference(asterisk(labels.LessLivingExpenses))})`,
    );

  const lenderSpecificPart = Object.entries(form.lenderSpecificDebtLineItems || {});
  lenderSpecificPart.forEach(([key, value]) => {
    builder.addRowWithStaticValue(key, value, { rowDataType: "text" });
  });

  builder
    .addRowWithStaticValue("TotalDebtService", 0)
    .addRowWithFormula(
      "PersonalDSCR",
      (finders, labels) =>
        `=${finders.columnReference(labels.CashFlowAvailableForDebtService)}/${finders.columnReference(
          labels.TotalDebtService,
        )}`,
    )
    .addRowWithFormula(
      "ExcessCashFlow",
      (finders, labels) =>
        `=${finders.columnReference(labels.CashFlowAvailableForDebtService)} - ${finders.columnReference(
          labels.TotalDebtService,
        )}`,
    );

  if (enableLoanCalculator) {
    const loanCalculatorData: LoanCalculatorData = {
      excessCashflow: builder.referenceInColumn(
        Form1040RowHeadings.CashFlowAvailableForDebtService,
      ),
      lenderId: (form.lenderId ? form.lenderId : "1") as SupportedLenderId,
      newDebt: "0",
    };

    const loanCalcStartingRow = builder.body.length + startingRow;
    const loanCalculatorPart = new LoanCalculatorRendered(
      loanCalculatorData,
      loanCalcStartingRow,
    ).asColumns();

    loanCalculatorPart.forEach((row) => {
      builder.addRow(() => row);
    });
  }

  return builder;

  function calculateInterestAndDividends(proxyData: Form1040): number {
    const { income } = proxyData;
    return (
      (income?.taxExemptInterest || 0) +
      (income?.taxableInterest || 0) +
      (income?.ordinaryDividends || 0)
    );
  }

  function calculatePensionsAndAnnuities(proxyData: Form1040): number {
    return (
      (proxyData.income?.pensionsAndAnnuities || 0) +
      (proxyData.income?.pensionsAndAnnuitiesTaxable || 0)
    );
  }
  function calculateFICADeduction(wageCell: string) {
    const year = form.year;
    const config = ficaCalculationConfiguration[year] ?? ficaCalculationConfiguration["2020"];
    const socialSecurityDeduction = `IF(${wageCell} > ${config.socialSecurityThreshold}, ${config.socialSecurityThreshold} * ${config.socialSecurity}, ${wageCell} * ${config.socialSecurity})`;
    const medicareDeduction = `${wageCell} * ${config.medicare}`;
    const extraMedicareDeduction = `IF(${wageCell} > ${config.additionalMedicareThreshold}, (${wageCell} - ${config.additionalMedicareThreshold}) * ${config.additionalMedicare}, 0)`;

    return `=ROUND(${socialSecurityDeduction} + ${medicareDeduction} + ${extraMedicareDeduction}, 2)`;
  }
  function scheduleCEBIDACalculation() {
    if (!scheduleCTabName || !form.schedules?.scheduleC?.length) {
      return 0;
    }
    const scheduleCCount = form.schedules?.scheduleC?.length ?? 0;
    const row = scheduleCCount + 2;
    return `='${scheduleCTabName}'!H${row}`;
  }

  function scheduleENetIncomeCalculation() {
    if (!scheduleETabName || !hasScheduleEProperties()) {
      return 0;
    }
    return `='${scheduleETabName}'!${scheduleETotalColumn()}${2}`;
  }

  function scheduleEInterestExpenseCalculation() {
    if (!scheduleETabName || !hasScheduleEProperties()) {
      return 0;
    }
    const interest = `'${scheduleETabName}'!${scheduleETotalColumn()}${4}`;
    const otherInterest = `'${scheduleETabName}'!${scheduleETotalColumn()}${5}`;
    return `=${interest} + ${otherInterest}`;
  }

  function scheduleEDepreciationAndAmortizationCalculation() {
    if (!scheduleETabName || !hasScheduleEProperties()) {
      return 0;
    }
    const depreciation = `'${scheduleETabName}'!${scheduleETotalColumn()}${3}`;
    const amortization = `'${scheduleETabName}'!${scheduleETotalColumn()}${6}`;
    return `=${depreciation} + ${amortization}`;
  }

  function hasScheduleEProperties() {
    return form.schedules?.scheduleE?.properties?.length ?? 0 > 0;
  }

  function scheduleETotalColumn() {
    const numberOfProperties = form.schedules?.scheduleE?.properties?.length ?? 0;
    return builder.colNumberToExcelCol(numberOfProperties + 2);
  }

  function calculateSubtotal(
    columnReference: (header: string, startAt?: StartAt) => string,
    labels: typeof Form1040RowHeadings,
  ) {
    const wagesAndInterest = `SUM(${columnReference(labels.WagesAndSalaries)}:${columnReference(
      labels.InterestAndDividends,
    )})`;
    const pensionsToCapitalGains = `SUM(${columnReference(labels.PensionsAndAnnuities)}:${columnReference(
      labels.ScheduleDCapitalGains,
    )})`;
    const scheduleEToK1 = `SUM(${columnReference(labels.ScheduleENetIncome)}:${columnReference(
      asterisk(labels.K1DistributionsNetContributions),
    )})`;

    if (shouldHideManualRows) {
      return `=${wagesAndInterest} + ${pensionsToCapitalGains} + ${scheduleEToK1}`;
    }

    const lessInterestAndDividendIncomeFromK1sCell = columnReference(
      labels.LessInterestAndDividendIncomeFromK1s,
    );
    const lessCapitalGainsOrAddBackCapitalLossesFromK1sCell = columnReference(
      labels.LessCapitalGainsOrAddBackCapitalLossesFromK1s,
    );
    const scheduleFCashFlowCell = columnReference(labels.ScheduleFCashFlow);

    return `=${wagesAndInterest} - ${lessInterestAndDividendIncomeFromK1sCell} + ${pensionsToCapitalGains} - ${lessCapitalGainsOrAddBackCapitalLossesFromK1sCell} + ${scheduleEToK1} + ${scheduleFCashFlowCell}`;
  }

  function asterisk(header: string) {
    return shouldHideManualRows ? header : `${header} *`;
  }
}
