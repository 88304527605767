import { BoundingBox } from "@aws-sdk/client-textract";
import { ColDef } from "ag-grid-community";
import {
  CellValue,
  ColumnRowIndex,
  HyperFormula,
  RawCellContent,
  SimpleCellAddress,
} from "hyperformula";
import { TabName, Typed } from "src/redux/reducers/types";
import { colNumberToExcelCol } from "./RenderedDoc";
import { defaultHyperFormulaConfig } from "src/utils/defaults";

export type RowId = Typed<string, "RowId">;
export interface GridState {
  [key: RowId]: GridRow;
}

export type RowMetadata = {
  levelIndex?: number;
};

export type BoundingBoxContext = {
  page: number;
  coordinates: BoundingBox;
};

export type GridRow = {
  rowDataArray: RawCellContent[];
  rowConfidence?: number[];
  rowGeometry?: BoundingBoxContext[];
  rowDataType: GridRowDataType;
  rowStyle: RowStyle;
  rowMetadata?: RowMetadata;
  isManagedByApp: boolean;
  isEditable?: boolean;
  index: number;
};

// we need a slightly differerent version of rowDataArray which cannot contain undefined cells

export type JsonSafeRawCellContent = string | number | boolean | null;

export type GridRowDataType = "date" | "number" | "percentage" | "text" | "ratio" | "formula";

export type RowStyle = "highlighted" | "metadata" | "standard";

export type RowData = { id: string } & Record<string, string>;

export function columnIdToIndex(columnId: string): number {
  if (!columnId.match(/^[A-Z]+$/)) {
    throw new Error("Input must be upper case letters. [A-Z]+");
  }
  let result = 0;
  for (let i = 0; i < columnId.length; i++) {
    result = result * 26 + columnId.charCodeAt(i) - 65 + 1;
  }
  return result - 1;
}

export function columnIndexToId(index: number): string {
  return colNumberToExcelCol(index + 1);
}

export module GridStateOperations {
  export function generateSheets(gridStates: Record<TabName, GridState>): {
    rawSheetData: Record<TabName, RawCellContent[][]>;
    calculatedSheetData: Record<TabName, CellValue[][]>;
    confidenceData?: Record<TabName, (number | null)[][]>;
    geometryData?: Record<TabName, (BoundingBoxContext | null)[][]>;
  } {
    const cellData: Record<TabName, RawCellContent[][]> = {};
    const confidenceData: Record<TabName, (number | null)[][]> = {};
    const geometryData: Record<TabName, (BoundingBoxContext | null)[][]> = {};

    Object.entries(gridStates).forEach(([tabName, gridState]) => {
      const rows = Object.values(gridState);
      cellData[tabName as TabName] = rows.map((row) => row.rowDataArray);

      // Extract confidence data
      confidenceData[tabName as TabName] = rows.map(
        (row) => row.rowConfidence ?? row.rowDataArray.map(() => null),
      );

      // Extract geometry data
      geometryData[tabName as TabName] = rows.map(
        (row) => row.rowGeometry ?? row.rowDataArray.map(() => null),
      );
    });

    const hf = HyperFormula.buildFromSheets(cellData, defaultHyperFormulaConfig);
    const rawSheetData = hf.getAllSheetsSerialized();
    const calculatedSheetData = hf.getAllSheetsValues();

    return {
      rawSheetData,
      calculatedSheetData,
      confidenceData,
      geometryData,
    };
  }
  export function _updateGridFromCells(gridState: GridState, cells: RawCellContent[][]): GridState {
    const updatedGridState: GridState = {};
    cells.forEach((row, index) => {
      const transformedRowDataArray = [...row].map((cell) => {
        if (cell instanceof Date) {
          return cell.toLocaleDateString("en-US");
        } else if (cell === undefined) {
          return null;
        } else {
          return cell;
        }
      });

      const originalKey = Object.keys(gridState)[index] as RowId;
      if (!originalKey) {
        console.warn(`No original key found for row at index ${index}.`);
        return;
      }

      const originalEntry = gridState[originalKey];
      if (!originalEntry) {
        console.warn(`No original entry found for key ${originalKey}.`);
        return;
      }

      updatedGridState[originalKey] = {
        ...originalEntry,
        rowDataArray: transformedRowDataArray,
      };
    });
    return _orderGridState(updatedGridState);
  }

  export function _addRow(
    gridState: GridState,
    rowIndex: number,
    where: "above" | "below",
    row?: RawCellContent[],
  ): GridState {
    const entries = Object.entries(gridState || {});
    const width = entries[0][1].rowDataArray.length;
    const newRowKey = `row-${Date.now()}`;
    const rowDataArray = row ?? Array.from({ length: width }, () => "");
    const newRowObject = {
      rowDataArray, // Blank strings for each header
      rowStyle: "standard" as RowStyle,
      rowDataType: "text" as GridRowDataType,
      isManagedByApp: false,
    };

    const insertPosition = where === "above" ? rowIndex : rowIndex + 1;
    entries.splice(insertPosition, 0, [newRowKey, newRowObject]);
    return Object.fromEntries(entries);
  }

  /*
    When updating a row:

    1. We need to use the underlying Hyperformula class to do the operations.
        This will allow the underlying formulas to be automatically updated.

    2. Then, we need to insert the row into the grid state in the appropriate place

    3. Finally, we need to iterate over hyperformula data and copy it back into the grid states.
    */
  export function addRow(
    gridStates: Record<TabName, GridState>,
    tabName: TabName,
    rowIndex: number,
    where: "above" | "below",
    row: RawCellContent[],
    sheets: Record<TabName, RawCellContent[][]>,
    colDefs: Record<TabName, ColDef[]>,
  ): Record<TabName, GridState> {
    const newGridStates = { ...gridStates };
    const hf = HyperFormula.buildFromSheets(sheets, defaultHyperFormulaConfig);

    const sheetId = hf.getSheetId(tabName);
    if (sheetId === undefined) {
      throw new Error(`Sheet ${tabName} not found, we only know about ${Object.keys(sheets)}`);
    }
    const adjustedRowIndex = where === "above" ? rowIndex : rowIndex + 1;
    const colRowIndex: ColumnRowIndex = [adjustedRowIndex, 1];
    const topLeftCorneOfChange: SimpleCellAddress = {
      col: 0,
      row: adjustedRowIndex,
      sheet: sheetId,
    };
    const changedCells = hf.addRows(sheetId, colRowIndex);
    hf.setCellContents(topLeftCorneOfChange, [row]);
    hf.rebuildAndRecalculate();

    const newSheetData = hf.getAllSheetsSerialized();

    const gridState = newGridStates[tabName];
    if (!gridState) {
      throw new Error(`Sheet ${tabName} not found in gridStates`);
    }

    newGridStates[tabName] = _addRow(gridState, rowIndex, where);

    // When we call hf.addRows we get a return object which tells us which cells have been updatd
    // that can be used to make this more elegant/efficient
    Object.entries(newSheetData).forEach(([sheetName, sheet]) => {
      const gridState = _updateGridFromCells(newGridStates[sheetName as TabName] || {}, sheet);
      newGridStates[sheetName as TabName] = gridState;
    });

    return newGridStates;
  }

  export function removeRow(
    gridStates: Record<TabName, GridState>,
    rowIndex: number,
    tabName: TabName,
    sheets: Record<TabName, RawCellContent[][]>,
  ): Record<TabName, GridState> {
    const newGridStates = { ...gridStates };
    const hf = HyperFormula.buildFromSheets(sheets, defaultHyperFormulaConfig);
    const sheetId = hf.getSheetId(tabName);
    if (sheetId === undefined) {
      throw new Error(`Sheet ${tabName} not found, we only know about ${Object.keys(sheets)}`);
    }
    const colRowIndex: ColumnRowIndex = [rowIndex, 1];
    const _changedCells = hf.removeRows(sheetId, colRowIndex);
    hf.rebuildAndRecalculate();

    const newSheetData = hf.getAllSheetsSerialized();

    const gridState = newGridStates[tabName];
    if (!gridState) {
      throw new Error(`Sheet ${tabName} not found in gridStates`);
    }

    Object.entries(newSheetData).forEach(([sheetName, sheet]) => {
      const gridState = _updateGridFromCells(newGridStates[sheetName as TabName] || {}, sheet);
      newGridStates[sheetName as TabName] = gridState;
    });

    return newGridStates;
  }

  function _updateCell(
    gridState: GridState,
    rowIndex: number,
    columnIndex: number,
    newValue: RawCellContent,
  ): GridState {
    const rowId = Object.keys(gridState)[rowIndex];
    const updatedRow = { ...gridState[rowId as RowId] };
    if (!updatedRow) {
      throw new Error(`Row index ${rowIndex} not found in grid state.`);
    }

    const updatedRowDataArray = updatedRow.rowDataArray.slice();
    updatedRowDataArray[columnIndex] = newValue;
    return {
      ...gridState,
      [rowId]: {
        ...updatedRow,
        rowDataArray: updatedRowDataArray,
      },
    };
  }

  export function updateCell(
    gridStates: Record<TabName, GridState>,
    tabName: TabName,
    rowIndex: number,
    columnIndex: number,
    newValue: RawCellContent,
    sheets: Record<TabName, RawCellContent[][]>,
    colDefs: Record<TabName, ColDef[]>,
  ): {
    newGridStates: Record<TabName, GridState>;
    rawSheetData: Record<TabName, RawCellContent[][]>;
    calculatedSheetData: Record<TabName, CellValue[][]>;
  } {
    const newGridStates = { ...gridStates };
    const hf = HyperFormula.buildFromSheets(sheets, defaultHyperFormulaConfig);
    const sheetId = hf.getSheetId(tabName);
    if (sheetId === undefined) {
      throw new Error(`Sheet ${tabName} not found, we only know about ${Object.keys(sheets)}`);
    }

    const cellAddress: SimpleCellAddress = { col: columnIndex, row: rowIndex, sheet: sheetId };
    hf.setCellContents(cellAddress, [[newValue]]);
    hf.rebuildAndRecalculate();

    const rawSheetData = hf.getAllSheetsSerialized();
    const calculatedSheetData = hf.getAllSheetsValues();
    const gridState = { ...newGridStates[tabName] };
    if (!gridState) {
      throw new Error(`Sheet ${tabName} not found in gridStates`);
    }

    const updatedGridState = _updateCell({ ...gridState }, rowIndex, columnIndex, newValue);
    newGridStates[tabName] = updatedGridState;

    Object.entries(rawSheetData).forEach(([sheetName, sheet]) => {
      const gridState = _updateGridFromCells(newGridStates[sheetName as TabName] || {}, sheet);
      newGridStates[sheetName as TabName] = gridState;
    });

    return { newGridStates, rawSheetData, calculatedSheetData };
  }

  // We need to have the gridState in a specific order for the spread sheet formulas to work.
  // After being restored from the DB the ordering is messed up.
  // We reconstruct the ordering from the index on RowData
  function _orderGridState(gridState: GridState): GridState {
    return Object.fromEntries(
      Object.entries(gridState)
        .sort((a, b) => a[1].index - b[1].index)
        .map(([key, value]) => [key, value]),
    );
  }

  export function reOrderGridStates(
    gridStates: Record<TabName, GridState>,
    colDefs: Record<TabName, ColDef[]>,
  ): Record<TabName, GridState> {
    const newGridStates = { ...gridStates };
    Object.entries(gridStates).forEach(([tabName, gridState]) => {
      newGridStates[tabName as TabName] = _orderGridState(gridState);
    });
    return newGridStates;
  }

  // Given a GridState, assume that cells is the cell representation of the grid state
  // with an additional row on top
  // we essentially just need to bump all the style information down by one
  export function bootstrapComposedCellsFromTemplate(
    cells: RawCellContent[][],
    template: GridState,
  ): GridState {
    const newGridState = { ...template };
    const entries = Object.entries(newGridState);
    cells.forEach((rowDataArray, index) => {
      if (index === 0) {
        // Do nothing for the first row
        return;
      }
      const rowId = `row-${index}` as RowId;
      if (!entries[index - 1]) {
        return;
      }
      const [prevRowId, row] = entries[index - 1];
      newGridState[rowId as RowId] = {
        ...row,
        rowDataArray,
      };
    });

    newGridState["row-0" as RowId].rowDataArray = cells[0];
    return newGridState;
  }

  export function bootstrapStackedCellsFromTemplate(
    cells: RawCellContent[][],
    template: GridState,
  ): GridState {
    const newGridState: GridState = {};
    const values = Object.values(newGridState);
    cells.forEach((rowDataArray, index) => {
      const rowId = `row-${index}` as RowId;
      const entryIndex = Math.floor(index / 2);
      if (!values[entryIndex]) {
        newGridState[rowId as RowId] = {
          rowDataArray,
          rowDataType: "text",
          rowStyle: "standard",
          isManagedByApp: false,
          index,
        };
        return;
      }
      const row = values[entryIndex];
      newGridState[rowId as RowId] = {
        ...row,
        rowDataArray,
        index,
      };
    });
    return newGridState;
  }
}
